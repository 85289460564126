<template>
    <div>
        <div
            class="tool-bar-container text-right pr-5"
        >
            <div stylde="float: right;">
                <div v-if="!loggedIn">
                    <div
                        id="login_btn"
                        class="login_btn mr-5 mt-1"
                        @click="loginDialog = true"
                    >
                        LOG IN
                    </div>
                </div>
                <div v-else>
                    <div class="account-controls">
                        <!-- Welcome, {{ user }} -->
                        <!-- <span class="account" @click="goToAccount">Account</span> -->
                        <div
                            class="nutri_btn mr-5"
                            @click="navigate"
                        >
                            DSS NutriSense
                        </div>
                    </div>
                </div>
            </div>
            <div  v-if="loggedIn">
                <div class="account-controls">
                    <!-- Welcome, {{ user }} -->
                    <span class="account" @click="goToAccount">Account</span>
                    <span class="account" @click="logout">Logout</span>
                </div>
            </div>
            <div class="language-selection mt-2">
                <span @click="setLanguage('gr')">GR</span> | <span @click="setLanguage('en')">EN</span>
            </div>
        </div>
        <v-dialog
            v-model="loginDialog"
            max-width="50vw"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Login</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="username"
                                    label="Username"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="password"
                                    label="Password"
                                    type="password"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-right">
                                <div class="forgot-password" @click="forgotPassword">
                                    <span v-if="language === 'en'">
                                        Forgot password
                                    </span>
                                    <span v-else>
                                        Ανάκτηση κωδικού
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                    elevation="0"
                                    color="#6BB00B"
                                    class="mt-4 login"
                                    @click="login"
                                >
                                    Log in
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
            <div>
                <form action="/crm/wp-login.php" method="POST" ref="loginForm">
                    <input type="hidden" name="log" v-model="username">
                    <input type="hidden" name="pwd" v-model="password">
                    <input type="hidden" name="rememberme" value="forever">
                    <input type="hidden" name="wp-submit" value="Log In">
                    <input type="hidden" name="testcookie" value="1">
                    <input type="hidden" name="redirect_to" value="main_website">
                </form>
            </div>
        </v-dialog>
        <v-dialog
            v-model="selectProductDialog"
            max-width="50vw"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">
                        <span v-if="language === 'en'">
                            Select Version
                        </span>
                        <span v-else>
                            Επιλογή έκδοσης
                        </span>
                    </span>
                </v-card-title>
                <v-card-text>
                    <div>
                        <v-row>
                            <v-col cols="12">
                                <div v-if="language === 'en'">
                                    Your selection will remain for the whole duration of your subscription.<br>
                                    Subscription to <strong>Professional</strong> option offers access to both versions.
                                </div>
                                <div v-else>
                                    Η επιλογή σας θα παραμείνει για το υπόλοιπο της συνδρομής σας.<br>
                                    Με τη συνδρομή <strong>Professional</strong> έχετε πρόσβαση και στις δύο εκδόσεις.
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" offset="2">
                                <v-btn
                                    elevation="0"
                                    color="#6BB00B"
                                    class="mt-4 login"
                                    @click="selectEdition('soilless')"
                                >
                                    Soilless Nutrisense
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                                    elevation="0"
                                    color="#6BB00B"
                                    class="mt-4 login"
                                    @click="selectEdition('soil')"
                                >
                                    Soil Nutrisense
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import user from '@/services/user'
    export default {
        data() {
            return {
                loginDialog: false,
                username: '',
                password: '',
                loggedIn: false,
                user: '',
                selectedProduct: null,
                selectProductDialog: false
            }
        },
        computed: {
            language() {
                return this.$store.getters.getLanguage
            }
        },
        created() {
            user.isLoggedIn().then((resp) => {
                this.loggedIn = true
                this.user = resp.data
            })
            this.getSelectedProduct()
        },
        methods: {
            login() {
                user.login(this.username, this.password).then(resp => {
                    console.log(resp.data)
                    this.$refs.loginForm.submit()
                })
            },
            goToAccount() {
                this.$router.push({ name: 'account', replace: true })
            },
            navigate() {
                if (this.selectedProduct === 'NULL') {
                    this.selectProductDialog = true
                } else {
                    window.location = 'http://calculator.nutrisense.online/'
                }
            },
            setLanguage(lang) {
                this.$store.dispatch('setLanguage', lang)
            },
            logout() {
                user.logout().then(() => {
                    window.location = '/'
                })
            },
            forgotPassword() {
                this.loginDialog = false
                this.$router.push({ name: 'ResetPassword', replace: true })
            },
            getSelectedProduct() {
                user.getSelectedProduct().then(resp => {
                    this.selectedProduct = resp.data
                })
            },
            selectEdition(edition) {
                user.selectEdition(edition).then(() => {
                    window.location = 'http://calculator.nutrisense.online/'
                })
            }
        }
    }
</script>

<style scoped>
.tool-bar-container {
    background: #000000;
    height: 40px;
    width: 100%;
    color: #CED94C;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.login_btn {
    color: #000;
    border-radius: 30px;
    height: 20px;
    background: #CED94C;
    width: 90px;
    text-align: center;
    padding-top: .1em;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}
.login {
    color: #fff!important;
}
.account {
    color: #CED94C;
    margin-top: 7px;
    margin-right: 10px;
    text-decoration: underline;
    cursor: pointer;
}
.account-controls {
    color: #fff;
    padding-top: 7px;
}
.nutri_btn {
    color: #fff;
    border-radius: 30px;
    height: 20px;
    background: #CED94C;
    width: 150px;
    text-align: center;
    padding-top: .015em;
    float: right;
    cursor: pointer;
}
.language-selection span {
    cursor: pointer;
}
.forgot-password {
    color: #000000ea;
    cursor: pointer;
    text-decoration: underline;
}
</style>
