export default {
    state: {
        language: localStorage.getItem('language') === null ? 'gr' : localStorage.getItem('language')
    },
    mutations: {
        setToken(state, value) {
            state.token = value
        },
        setDisplay(state, value) {
            state.display = value
        },
        setDrawer(state, value) {
            state.drawer = value
        },
        setLanguage(state, value) {
            state.language = value
            window.location.reload()
        }
    },
    actions: {
        logout({ dispatch }) {
            dispatch('setToken', null)
            dispatch('setDrawer', null)
        },
        login({ dispatch }, [resp]) {
            if (resp.data.token) {
                dispatch('setToken', resp.data.token)
                dispatch('setDrawer', true)
            }
        },
        setToken({ commit }, token) {
            localStorage.setItem('api-token', token)
            commit('setToken', token)
        },
        setDisplay({ commit }, display) {
            localStorage.setItem('display', display)
            commit('setDisplay', display)
        },
        setDrawer({ commit }, drawer) {
            localStorage.setItem('drawer', drawer)
            commit('setDrawer', drawer)
        },
        setLanguage({ commit }, language) {
            localStorage.setItem('language', language)
            commit('setLanguage', language)
        }
    },
    getters: {
        getToken: state => state.token,
        isLoggedIn(state) {
            // is string null because of local storage
            return state.token !== null
        },
        getDisplay: state => state.display,
        getDrawer: state => state.drawer,
        getLanguage: state => state.language
    }
}
