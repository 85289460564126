import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import jwtUtil from '@/utils/jwtUtil'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Home')
    },
    {
        path: '/what-is-nutrisense',
        name: 'About_A',
        component: () => import('@/routes/' + store.getters.getLanguage + '/About_A')
    },
    {
        path: '/SOILLESS-NutriSense',
        name: 'About_B',
        component: () => import('@/routes/' + store.getters.getLanguage + '/About_B')
    },
    {
        path: '/SOIL-NUTRISENSE',
        name: 'About_C',
        component: () => import('@/routes/' + store.getters.getLanguage + '/About_C')
    },
    {
        path: '/relevant-projects',
        name: 'About_D',
        component: () => import('@/routes/' + store.getters.getLanguage + '/About_D')
    },
    {
        path: '/relevant-papers',
        name: 'About_E',
        component: () => import('@/routes/' + store.getters.getLanguage + '/About_E')
    },
    {
        path: '/the-team',
        name: 'partners',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Partners')
    },
    {
        path: '/the-team/ntatsi',
        name: 'partners_ntatsi',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Partners_Ntatsi')
    },
    {
        path: '/the-team/giannothanasis',
        name: 'partners_giannothanasis',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Partners_Giannothanasis')
    },
    {
        path: '/the-team/karavidas',
        name: 'partners_Karavidas',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Partners_Karavidas')
    },
    {
        path: '/the-team/Ntanasi',
        name: 'partners_Ntanasi',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Partners_Ntanasi')
    },
    {
        path: '/the-team/D-Savvas',
        name: 'partners_DSavvas',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Partners_Savvas')
    },
    {
        path: '/advisory-packages',
        name: 'advisoryPackages',
        component: () => import('@/routes/' + store.getters.getLanguage + '/AdvisoryPackages')
    },
    {
        path: '/seminars',
        name: 'seminars',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Seminars')
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/routes/Account')
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import('@/routes/Checkout')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Contact')
    },
    {
        path: '/instrusctions',
        name: 'instructions',
        component: () => import('@/routes/' + store.getters.getLanguage + '/Instructions')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/routes/ResetPassword')
    }
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: () => import('@/routes/Login'),
    //     props: true
    // },
    // {
    //     path: '/account',
    //     name: 'Account',
    //     component: () => import('@/routes/Account')
    // },
    // {
    //     path: '/screens',
    //     name: 'Screens',
    //     component: () => import('@/routes/Screen')
    // },
    // {
    //     path: '/screens/:screenId/:mode',
    //     name: 'Screen Edit Form',
    //     component: () => import('@/forms/ScreenForm')
    // },
    // {
    //     path: '/screens/:mode',
    //     name: 'Screen Create Form',
    //     component: () => import('@/forms/ScreenForm')
    // },
    // {
    //     path: '/areas',
    //     name: 'Areas',
    //     component: () => import('@/routes/Area')
    // },
    // {
    //     path: '/areas/:areaId/:mode',
    //     name: 'Area Edit Form',
    //     component: () => import('@/forms/AreaForm')
    // },
    // {
    //     path: '/areas/:mode',
    //     name: 'Announcement Create Form',
    //     component: () => import('@/forms/AnnouncementForm')
    // },
    // {
    //     path: '/announcements',
    //     name: 'Announcements',
    //     component: () => import('@/routes/Announcement')
    // },
    // {
    //     path: '/announcements/:announcementId/:mode',
    //     name: 'Announcement Edit Form',
    //     component: () => import('@/forms/AnnouncementForm')
    // },
    // {
    //     path: '/announcements/:mode',
    //     name: 'Announcement Create Form',
    //     component: () => import('@/forms/AnnouncementForm')
    // }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    next()
})

export default router
