<template>
    <div class="footer-container mt-14">
        <div class="inner-container">
            <!-- <v-img :width="1917" :height="617" class="portrait d-none" :src="require('@/assets/FOOTER_latest.png')" /> -->
            <v-row>
                <v-col cols="8">
                    <div class="head_text mb-6">
                        QUICK LINKS
                    </div>
                    <v-row>
                        <v-col cols="2">
                            <span @click="$router.push({ name: 'home', replace: true })" href="" class="link">Home</span><br>
                            <span @click="$router.push({ path: '/#pricing', replace: true })" href="" class="link">Pricing</span><br>
                            <span @click="$router.push({ name: 'partners', replace: true })" href="" class="link">Team</span><br>
                            <span @click="$router.push({ name: 'contact', replace: true })" href="" class="link">Contact</span><br>
                        </v-col>
                        <v-col cols="5">
                            <span @click="$router.push({ name: 'About_E', replace: true })" href="" class="link">Relevant Papers</span><br>
                            <span @click="$router.push({ name: 'instructions', replace: true })" href="" class="link">Instructions</span><br>
                            <span href="" class="link">FAQs</span><br>
                        </v-col>
                        <v-col cols="5">
                            <span @click="$router.push({ name: 'About_D', replace: true })" href="" class="link">Relevant Projects</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="3" offset="1">
                    <div class="head_text mb-6">
                        CONTACT INFO
                    </div>
                    <div class="mt-4">
                        Email: <br>
                        nutrisense@nutrisense.online
                        <br><br>
                        Phone: <br>
                        +30 694 836 8915<br>+30 698 684 6648

                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped>
.footer-container {
    min-height: 350px;
    background: #000;
    color: #fff;
    font-size: 20px;
}
.head_text {
    color: #71a253;
    font-weight: bold;
    margin-top: 40px;
}
.inner-container {
    width: 40%;
    margin: auto;
}
.link {
    text-decoration: none;
    color: #fff;
}
span {
    cursor: pointer;
}
</style>
