import api from '@/utils/api'

export default {
    login(username, password) {
        let bodyFormData = new FormData()
        bodyFormData.append('username', username)
        bodyFormData.append('password', password)
        return api.post(`private/fApi/login.php`, bodyFormData)
    },
    isLoggedIn() {
        return api.get(`private/fApi/islogged.php`)
    },
    logout() {
        return api.get(`private/fApi/logout.php`)
    },
    hasSubscription() {
        return api.get(`private/fApi/hasSubscription.php`)
    },
    getSelectedProduct() {
        return api.get(`private/fApi/selectedProduct.php`)
    },
    selectEdition(edition) {
        return api.get(`private/fApi/selectEdition.php?edition=${edition}`)
    }
}
