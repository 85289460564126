<template>
    <v-app>
        <div>
            <div>
                <user-bar />
            </div>
            <div :class="[{'sticky': sticky}]">
                <v-container class="custom-container" fluid>
                    <v-row no-gutters class="px-0 mx-0">
                        <v-col :cols="12">
                            <topbar :sticky="sticky" />
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <v-container class="custom-container" fluid>
                <!-- <v-row no-gutters class="px-0 mx-0">
                    <v-col :cols="12">
                        <topbar />
                    </v-col>
                </v-row> -->
                <v-row no-gutters>
                    <v-col>
                        <router-view :key="$route.path" />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <footerbar />
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-app>
</template>

<script>
    import Topbar from '@/components/Topbar'
    import Footerbar from './components/Footerbar.vue'
    import UserBar from '@/components/UserBar'

    export default {
        name: 'App',
        components: {
            Topbar,
            Footerbar,
            UserBar
        },
        data: () => ({
            sticky: false,
            style: 'position: sticky;top: 0;'
        }),
        computed: {
        },
        mounted() {
            // window.addEventListener('scroll', this.handleScroll)
        },
        methods: {
            handleScroll() {
                let scrollY = window.scrollY || window.pageYOffset
                const initialOffset = 400
                if (scrollY > initialOffset) {
                    window.setTimeout(() => {
                        this.sticky = true
                    }, 1)
                } else {
                    this.sticky = false
                }
            }
        }
    }
</script>

<style>
.custom-container {
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px!important;
}
.sticky {
    top: -5px;
    position: sticky;
    transition: top 2s;
    z-index: 1000;
}
</style>
