<template>
    <v-container>
        <!-- eslint-disable -->
        <v-row v-show="!sticky" style="min-height: 80px;">
            <v-col>
                <v-img :width="277" :height="73" class="logo" :src="require('@/assets/logo.png')" />
            </v-col>
        </v-row>
        <div>
            <v-row>
                <v-col>
                    <div class="menu-bar" :class="sticky ? 'sticky' : ''">
                        <span v-if="language === 'en'" @click="go('home')">
                            Home
                        </span>
                        <span v-else @click="go('home')">
                            Αρχική
                        </span>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <span v-if="language === 'en'">About the DSS NUTRISENSE</span>
                                    <span v-else>Σχετικά με το DSS NUTRISENSE</span>
                                </span>
                            </template>
                            <v-list class="list">
                                <v-list-item
                                    v-for="(item, index) in items"
                                    :key="index"
                                    @click="go(item.name)"
                                >
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <span @click="$router.push({ name: 'partners', replace: true })">
                            <span v-if="language === 'en'"> The team of NUTRISENSE</span>
                            <span v-else>Η Ομάδα του NUTRISENSE</span>
                        </span>
                        <span>
                            <span v-if="language === 'en'">News</span>
                            <span v-else>Νέα</span>
                        </span>
                        <span>
                            Forum
                        </span>
                        <span @click="$router.push({ name: 'instructions', replace: true })">
                            <span v-if="language === 'en'">Instructions</span>
                            <span v-else>Οδηγίες</span>
                        </span>
                        <span @click="$router.push({ path: '/#pricing', replace: true })">
                            <span v-if="language === 'en'">Pricing</span>
                            <span v-else>Τιμολόγηση</span>
                        </span>
                        <span @click="$router.push({ name: 'contact', replace: true })">
                            <span v-if="language === 'en'">Contact Us</span>
                            <span v-else>Επικοινωνία</span>
                        </span>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    /* eslint-disable no-alert, no-console */
    export default {
        props: {
            sticky: {
                typeof: Boolean,
                required: true
            }
        },
        data() {
            return {
            }
        },
        computed: {
            language() {
                return this.$store.getters.getLanguage
            },
            items() {
                if (this.language === 'en') {
                    return [
                        { title: 'What is NUTRISENSE', name: 'About_A' },
                        { title: 'SOILLESS NutriSense', name: 'About_B' },
                        { title: 'SOIL NutriSense', name: 'About_C' },
                        { title: 'Relevant Projects', name: 'About_D' },
                        { title: 'Relevant papers', name: 'About_E' }
                    ]
                } else {
                    return [
                        { title: 'Τι είναι το NUTRISENSE', name: 'About_A' },
                        { title: 'SOILLESS NutriSense', name: 'About_B' },
                        { title: 'SOIL NutriSense', name: 'About_C' },
                        { title: 'Ερευνητικά Έργα', name: 'About_D' },
                        { title: 'Σχετικές Δημοσιεύσεις', name: 'About_E' }
                    ]
                }
            }
        },
        methods: {
            go(pageName) {
                this.$router.push({ name: pageName, replace: true })
            }
        }
    }
</script>

<style scoped>
.menu-bar {
    background: #226100;
    border-radius: 15px;
    height: 76px;
    color: #fff;
    width: 1200px;
    margin: auto;
    display: grid;
    grid-auto-flow: column;
    align-content: space-evenly;
    padding-left: 3rem;
    cursor: pointer;
    z-index: 999;
    position: relative;
}

.logo {
    margin: auto;
}

.v-list-item {
    border-bottom: 1px solid #d5d5d5;
    color: #226100!important;
}
.v-list-item:last-child {
    border: none;
}
.v-list {
    cursor: pointer;
}
</style>
